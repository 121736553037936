import { KeyedResponseData } from '../../../../global/types'

import { LocationRule } from './LocationsInput'

export const stringToRules = (
  s: string | undefined,
): Map<string, LocationRule> => {
  if (!s?.replace(/\s/g, '')) {
    return new Map()
  }

  const splitRules = s
    .trim()
    // removes new line characters
    .replace(/[\r\n]/gm, '')
    // this character represents the end of a location in the string
    .split(';')
    .filter(it => it)
    // this separates the components of a location such as name$locationId$workflow or for newer ones name$locationId
    .map(it => it.split('$'))

  return splitRules.reduce((map, [name, locationId, workflow]) => {
    map.set(locationId, {
      name,
      locationId: locationId.replace(';', ''),
      workflow: workflow?.replace(';', ''),
      selected: true,
    })
    return map
  }, new Map())
}

export const rulesToString = (r: LocationRule[]) =>
  r
    .map(
      it =>
        `${it.name}$${it.locationId}${it.workflow ? `$${it.workflow}` : ''};`,
    )
    .join('\n')

export const hasDuplicateRuleNames = (changes: KeyedResponseData): boolean => {
  let seen = new Set()
  return Object.keys(changes.workflows).some(it => {
    const rules = stringToRules(changes.workflows[it].rules)
    const hasDuplicates = Array.from(rules.values()).some(
      current => seen.size === seen.add(current.name).size,
    )
    seen = new Set()
    return hasDuplicates
  })
}
