import React, { useState } from 'react'
import Frame from 'react-frame-component'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
} from '@deliveryhero/armor'
import { SmartphoneIcon, LaptopIcon } from '@deliveryhero/armor-icons'

import { LandingPageRenderer } from '../../../components/LandingPageRenderer'
import { DenormalizedLandingPage } from '../selectors'

const AlignLeftGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
`

const DialogContentPreview = styled(DialogContent)`
  background-color: #f7f7f7;
  padding: 16px;
  display: flex;
  flex-direction: center;
  justify-content: center;
  overflow-y: visible;
  padding-bottom: 16px !important;
`

// Min width is 375 that is the narrowest screen we support
const MobileVisualizer = styled(Frame)`
  border-radius: 16px;
  background-color: #fff;
  width: 375px;
  height: calc(100vh - 142px);
  border: 0;
  box-shadow: 5px 5px 17px 6px rgba(0, 0, 0, 0.1);
`

const DesktopVisualizer = styled(Frame)`
  border-radius: 16px;
  background-color: #fff;
  width: 100%;
  height: calc(100vh - 142px);
  border: 0;
  box-shadow: 5px 5px 17px 6px rgba(0, 0, 0, 0.1);
  margin-right: 16px;
  margin-left: 16px;
`

// This component is to invisibly render the lp so the CSS generated by armor is inserted in the head before we render the actual iframes
const HiddenPreview = styled.section`
  display: none;
`

export const Preview: React.FC<{
  isOpen: boolean
  landingPage: DenormalizedLandingPage
  onClose: () => void
  selectedLandingPageContent: number
}> = ({ isOpen, landingPage, onClose, selectedLandingPageContent }) => {
  const [t] = useTranslation()
  const [showMobileView, setShowMobileView] = useState(true)
  if (!landingPage || !isOpen) return null

  // Taken from https://stackoverflow.com/questions/2474605/how-to-convert-a-htmlelement-to-a-string
  const getHTML = (who: HTMLElement, deep: number) => {
    if (!who || !who.tagName) return ''
    let txt: string
    let ax
    let el = document.createElement('div')
    el.appendChild(who.cloneNode(false))
    txt = el.innerHTML
    if (deep) {
      ax = txt.indexOf('>') + 1
      txt = txt.substring(0, ax) + who.innerHTML + txt.substring(ax)
    }
    el = null

    // Taken from https://developer.mozilla.org/en-US/docs/Web/API/StyleSheetList#get_all_css_rules_for_the_document_using_array_methods
    const allCSS = [...document.styleSheets]
      .map(styleSheet => {
        try {
          return [...styleSheet.cssRules].map(rule => rule.cssText).join('')
        } catch (e) {
          console.log(
            'Access to stylesheet %s is denied. Ignoring...',
            styleSheet.href,
          )
          return ''
        }
      })
      .filter(Boolean)
      .join('\n')

    return `${txt}<style>${allCSS}</style>`
  }

  const RenderedLP = (
    <LandingPageRenderer
      landingPage={landingPage}
      selectedLandingPageContentIdx={selectedLandingPageContent}
      previewMode={false}
    />
  )

  return (
    <Dialog open={isOpen} minWidth="100vw" minHeight="100vh" disableCloseButton>
      <DialogTitle>
        <AlignLeftGrid>
          <div>
            <Button
              tertiary
              marginRight={12}
              disabled={!showMobileView}
              onClick={() => setShowMobileView(false)}
            >
              <LaptopIcon large />
            </Button>
            <Button
              tertiary
              disabled={showMobileView}
              onClick={() => setShowMobileView(true)}
            >
              <SmartphoneIcon large />
            </Button>
          </div>
          <Button onClick={onClose}>
            {t('LandingPage.Preview.ExitPreview')}
          </Button>
        </AlignLeftGrid>
      </DialogTitle>
      <DialogContentPreview>
        <HiddenPreview>{RenderedLP}</HiddenPreview>
        {showMobileView && (
          <MobileVisualizer
            width="375"
            head={
              typeof document !== 'undefined' ? (
                <head
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: getHTML(document.head, 8),
                  }}
                />
              ) : null
            }
          >
            {RenderedLP}
          </MobileVisualizer>
        )}
        {!showMobileView && (
          <DesktopVisualizer
            className="desktop-preview"
            head={
              typeof document !== 'undefined' ? (
                <head
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: getHTML(document.head, 8),
                  }}
                />
              ) : null
            }
          >
            <section className="landing-page-root">{RenderedLP}</section>
          </DesktopVisualizer>
        )}
      </DialogContentPreview>
    </Dialog>
  )
}
