import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Typography,
  Tab,
  TextInput,
  Switch,
  Grid,
  GridItem,
  styled,
  Button,
  Dropdown,
} from '@deliveryhero/armor'

import { TabView } from '../../../components/armor-overrides'
import { Field, Language } from '../../../global/types'
import { SUPPORTED_VALIDATIONS } from '../../../utils/field-validations'
import { snakeToTitleCase } from '../../../utils/strings'
import { DenormalizedLandingPage } from '../selectors'

import {
  CenteredTabs,
  GridHeader,
  RowAlignedGrid,
  Title,
  DescriptionBody,
  DescriptionNote,
} from './shared-components'

const WideTabView = styled(TabView)`
  width: 100%;
`

const AGE = 'age'
const STRING_FIELD = 'stringField'

type IProps = {
  landingPage: DenormalizedLandingPage
  handleLandingPageChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  setSelectedLanguage: (index: number) => void
  selectedLanguage: number
  availableLanguages: Language[]
  handleFieldChange: (
    _: string,
    id: string,
    property: string,
    value: string | { validations: string[] },
  ) => void
  onAddNewField: (selectedLanguage: number) => void
}

export const GeneralTab: React.FC<IProps> = ({
  landingPage,
  handleLandingPageChange,
  setSelectedLanguage,
  selectedLanguage,
  availableLanguages,
  handleFieldChange,
  onAddNewField,
}) => {
  const [t] = useTranslation()

  const fieldComponents = {
    // eslint-disable-next-line react/no-unstable-nested-components
    age: (field: { text: any; name: string; id: string }) => (
      <Typography>
        {t('Settings.General.TheApplicantMustBeOver')}
        {
          <TextInput
            style={{
              maxWidth: '4rem',
              marginBottom: '1.5rem',
              marginRight: 8,
              marginLeft: 8,
            }}
            value={`${field.text}`}
            label={snakeToTitleCase(field.name)}
            onChange={e =>
              handleFieldChange(field.name, field.id, 'text', e.target.value)
            }
          />
        }{' '}
        {t('Settings.General.Old')}
      </Typography>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    stringField: (field: { text: any; name: string; id: string }) => (
      <TextInput
        disabled={field.restOperation === 'POST'}
        style={{ marginBottom: '1.5rem' }}
        value={`${field.text}`}
        label={snakeToTitleCase(field.name)}
        onChange={e =>
          handleFieldChange(field.name, field.id, 'text', e.target.value)
        }
      />
    ),
  }

  const validationOptions = SUPPORTED_VALIDATIONS.map(validation => ({
    label: t(`Settings.General.${validation}`),
    value: validation,
  }))

  const onValidationChange =
    (field: Field) => (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFieldChange(field.name, field.id, 'settings', {
        validations: [e.target.value.toString()],
      })
    }

  return (
    <Grid>
      <GridItem>
        <TextInput
          label={t('Settings.General.ProjectNamePlaceholder')}
          name="name"
          large
          wide
          value={landingPage.name}
          onChange={handleLandingPageChange}
          marginBottom={6}
        />
        <TextInput
          name="baseUrl"
          label={t('Settings.General.BaseUrlPlaceholder')}
          large
          wide
          value={landingPage.baseUrl}
          onChange={handleLandingPageChange}
          marginBottom={3}
        />
        <TextInput
          label={t('Settings.General.LandingPageDomain')}
          name="landingPageDomain"
          large
          wide
          value={landingPage.landingPageDomain}
          onChange={handleLandingPageChange}
          marginBottom={6}
        />
        <TextInput
          label={t('Settings.General.PrivacyPolicyUrl')}
          name="privacyPolicyUrl"
          large
          wide
          value={landingPage.privacyPolicyUrl}
          onChange={handleLandingPageChange}
          marginBottom={6}
        />
        <TextInput
          label={t('Settings.General.CookiePolicyUrl')}
          name="cookiePolicyUrl"
          large
          wide
          value={landingPage.cookiePolicyUrl}
          onChange={handleLandingPageChange}
          marginBottom={6}
        />
        <DescriptionNote paragraph small>
          {t('Settings.General.ThisWillBeTheUrlForLandingPage')}
        </DescriptionNote>

        <Title subSectionTitle>{t('Settings.General.MainFormFields')}</Title>
        <DescriptionBody style={{ marginBottom: '1.5rem' }} paragraph small>
          {t('Settings.General.MainFormFieldsHelper')}
        </DescriptionBody>
      </GridItem>
      <GridItem>
        <CenteredTabs>
          {availableLanguages.map(({ name }, index) => (
            <Tab
              key={name}
              onClick={() => {
                setSelectedLanguage(index)
              }}
            >
              <Typography>{name}</Typography>
            </Tab>
          ))}
        </CenteredTabs>

        {availableLanguages.map((_, index) => (
          <WideTabView
            key={landingPage.landingPageContents[index].id}
            value={index}
            selectedValue={selectedLanguage}
          >
            <GridHeader>
              <RowAlignedGrid>
                <GridItem xs={6}>{t('Settings.General.Description')}</GridItem>
                <GridItem xs={3}>{t('Settings.General.Validation')}</GridItem>
                <GridItem xs={3}>{t('Settings.General.EnableField')}</GridItem>
              </RowAlignedGrid>
            </GridHeader>
            {landingPage.landingPageContents[index].fields.map(field => (
              <RowAlignedGrid
                key={field.id}
                data-testid="field-container"
                style={{ marginTop: 8 }}
              >
                <GridItem xs={6}>
                  {field.name === AGE
                    ? fieldComponents[AGE](field)
                    : fieldComponents[STRING_FIELD](field)}
                </GridItem>
                <GridItem xs={3}>
                  <Dropdown
                    data-testid="field-container-validation-dropdown"
                    placeholder={t('Settings.General.Validation')}
                    options={validationOptions}
                    onChange={onValidationChange(field)}
                    value={field.settings.validations?.[0]}
                  />
                </GridItem>

                <GridItem
                  xs={3}
                  style={{ paddingLeft: '8px', paddingTop: '0.6rem' }}
                >
                  <Switch
                    style={{ marginTop: '0.5rem' }}
                    disabled={field.restOperation === 'POST'}
                    checked={field.enabled}
                    onChange={e =>
                      handleFieldChange(
                        field.name,
                        field.id,
                        'enabled',
                        e.target.checked,
                      )
                    }
                  />
                </GridItem>
              </RowAlignedGrid>
            ))}

            <Button onClick={() => onAddNewField(selectedLanguage)}>
              {t('Settings.General.AddNewField')}
            </Button>
          </WideTabView>
        ))}
      </GridItem>
    </Grid>
  )
}
