import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  TextInput,
  Search,
  Tooltip,
  IconButton,
  FormField,
  Typography,
  Grid,
} from '@deliveryhero/armor'
import { InfoOutlineIcon, InfoIcon } from '@deliveryhero/armor-icons'

import { AraraLocation } from '../../arara.api'

import { LocationRule } from './LocationsInput'

export type IProps = {
  araraLocations: AraraLocation[]
  locations: Map<string, LocationRule>
  handleSelectedRuleChange: (checked: boolean, location: LocationRule) => void
  filterSelected: boolean
}

const mergeLocations = (
  araraLocations: AraraLocation[],
  locations: Map<string, LocationRule>,
) =>
  araraLocations.reduce((map, { name, id }) => {
    const loc = locations.get(id)
    map.set(id, {
      name: loc ? loc.name : name,
      locationId: id,
      selected: loc ? loc.selected : false,
      workflow: undefined,
    })

    return map
  }, new Map())

type RowProps = {
  viewLocations: Map<string, LocationRule>
  location: LocationRule
  onChange: (e: any) => void
  onSwitch: (e: any) => void
}

const LocationsRow: React.FC<RowProps> = ({
  viewLocations,
  location,
  onChange,
  onSwitch,
}) => {
  const [buffer, setBuffer] = useState(location.name)
  const [error, setError] = useState(false)
  useEffect(() => {
    setBuffer(location.name)
  }, [location])
  const debouncedChange = useDebouncedCallback(() => {
    onChange(buffer)
  }, 350)

  return (
    <TableRow>
      <TableCell>{location.locationId}</TableCell>
      <TableCell>
        <FormField autoMargin>
          <TextInput
            disabled={!location.selected}
            value={buffer}
            after={
              !location.selected ? (
                <Tooltip
                  enablePortal
                  zIndex={3999}
                  align="bottom-end"
                  content="You can only edit the Display name of enabled locations."
                >
                  <IconButton>
                    <InfoOutlineIcon small />
                  </IconButton>
                </Tooltip>
              ) : (
                error && (
                  <Tooltip
                    enablePortal
                    zIndex={3999}
                    align="bottom-end"
                    content="You can not have same display name for multiple locations"
                  >
                    <IconButton>
                      <InfoOutlineIcon small color="#D73D45" />
                    </IconButton>
                  </Tooltip>
                )
              )
            }
            error={error}
            onChange={e => {
              const hasDuplicates = Array.from(viewLocations.values()).some(
                it => it.name === e.target.value,
              )
              // this characters break the underlying string that we use to store the locations
              // we have to avoid them for now.
              const forbiddenCharaters = /[$;]/
              if (forbiddenCharaters.test(e.target.value)) {
                return
              }
              setBuffer(e.target.value)
              setError(hasDuplicates)
              debouncedChange()
            }}
          />
        </FormField>
      </TableCell>
      <TableCell>
        <Switch checked={location.selected} onChange={onSwitch} />
      </TableCell>
    </TableRow>
  )
}
export const LocationsTable = ({
  araraLocations,
  locations,
  handleSelectedRuleChange,
  filterSelected,
}: IProps) => {
  const [searchFilter, setSearchFilter] = useState('')

  const debouncedSearch = useDebouncedCallback(
    // function
    (value: string) => {
      setSearchFilter(value)
    },
    // delay in ms
    350,
  )

  const [viewLocations, setViewLocations] = useState(
    mergeLocations(araraLocations, locations),
  )

  useEffect(() => {
    setViewLocations(mergeLocations(araraLocations, locations))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations])

  const filteredLocations = Array.from(viewLocations.values()).filter(it => {
    if (filterSelected && !it.selected) {
      return false
    }
    if (
      searchFilter &&
      !it.name.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase())
    ) {
      return false
    }
    return true
  })
  return (
    <>
      <Search
        data-testid="search"
        marginBottom="16px"
        marginTop="16px"
        enableSuggestions={false}
        onChange={e => {
          debouncedSearch(e.target.value)
        }}
        icon={<InfoIcon small />}
        wide
      />

      {filteredLocations.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Location</TableCell>
              <TableCell width="246px">Display Name</TableCell>
              <TableCell width="93px">Enable</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLocations.map(it => (
              <LocationsRow
                viewLocations={viewLocations}
                key={it.locationId}
                location={it}
                onChange={e => {
                  handleSelectedRuleChange(it.selected, {
                    ...it,
                    name: e,
                  })
                }}
                onSwitch={e => {
                  handleSelectedRuleChange(e.target.checked, it)
                }}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Grid style={{ justifyContent: 'space-evenly', marginTop: '18px' }}>
          <Typography paragraph large>
            No locations found
          </Typography>
        </Grid>
      )}
    </>
  )
}
